'use strict';
import { loadGroups } from '../../../react/shared/store/groups';

import { showModal } from '@/react/angular/ReactModalBridge';

class GroupListComponent {
  constructor(
    _,
    $state,
    $uibModal,
    Authorization,
    Users,
    Groups,
    Me,
    toastr,
    gettextCatalog,
    $ngRedux
  ) {
    'ngInject';

    this._ = _;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.Authorization = Authorization;
    this.Users = Users;
    this.Groups = Groups;
    this.Me = Me;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    const unsubscribe = $ngRedux.connect(
      () => ({}),
      (dispatch) => ({
        refreshGroups: () => dispatch(loadGroups()),
      })
    )(this);
    this.$onDestroy = () => unsubscribe();
  }

  $onInit() {
    this.search = {};
    this.canAccessPlanning =
      this.Authorization.hasPermission('canAccessPlanning');
    this.sorting = { field: 'name', reverse: false };
    this.users = this.Users.query();
    this.loadGroups();
  }

  loadGroups() {
    const _ = this._;
    this.refreshGroups();
    this.Groups.query().$promise.then((res) => {
      const groups = _.map(res, (group) => {
        group.task = _.first(group.tasks);
        return group;
      });

      this.groups = {
        mine: _.filter(groups, (group) =>
          _.includes(group.members, this.Me.id)
        ),

        all: _.filter(
          groups,
          (group) => !_.includes(group.members, this.Me.id)
        ),
      };
    });
  }

  createGroup() {
    this.$uibModal
      .open({
        component: 'cdCreateGroupModal',
      })
      .result.then((newGroup) => {
        this.groups.mine.push(newGroup);
        this.loadGroups();
        this.toastr.success(
          this.gettextCatalog.getString(
            'The group "{{ groupName }}" has been created.',
            {
              groupName: newGroup.name,
            }
          )
        );
      });
  }

  onNewGroupCreated = () => {
    this.loadGroups();
  };

  editGroup(group) {
    this.$uibModal
      .open({
        component: 'cdCreateGroupModal',
        resolve: {
          group() {
            return _.extend({}, group, { hasTask: !!_.get(group, 'tasks[0]') });
          },
        },
      })
      .result.then(() => {
        this.loadGroups();
        this.toastr.success(
          this.gettextCatalog.getString('The group has been updated.')
        );
      });
  }

  joinGroup(group) {
    const groupId = group.id;
    const userId = this.Me.id;

    this.Groups.updateUserMembership(
      { groupId, userId },
      { status: 'pending' },
      (data) => {
        if (data.status === 'active') {
          this.toastr.success(
            this.gettextCatalog.getString('You joined a new group.')
          );

          this.refreshGroups();
          this._.remove(this.groups.all, { id: groupId });
          group.members.push(userId);
          this.groups.mine.push(group);
        } else {
          this.toastr.success(
            this.gettextCatalog.getString(
              'Your request to join the group has been sent.'
            )
          );
        }
      },
      () => {
        this.toastr.error(
          this.gettextCatalog.getString('Request could not be sent.')
        );
      }
    );
  }

  leaveGroup(group) {
    const groupId = group.id;
    const userId = this.Me.id;

    this.Groups.updateUserMembership(
      { groupId, userId },
      { status: 'remove' },
      () => {
        this.toastr.success(
          this.gettextCatalog.getString('You have been removed from the group.')
        );

        this.refreshGroups();
        this._.remove(this.groups.mine, { id: groupId });
        this._.pull(group.members, userId);
        this.groups.all.push(group);
      },
      () => {
        this.toastr.warning(
          this.gettextCatalog.getString(
            'We could not remove you from the group.'
          )
        );
      }
    );
  }

  addMember(group) {
    showModal('addMemberModal', {
      group: {
        name: group.name,
        id: group.id,
        members: group.members || [],
      },
    });
  }

  postMessage(group) {
    this.$uibModal
      .open({
        component: 'cdCreateGroupMessageModal',
        resolve: {
          groupId: () => group.id,
        },
      })
      .result.then(() => {
        this.toastr.success(
          this.gettextCatalog.getString('A new message has been created.')
        );
      });
  }

  getUserPicture(userId) {
    const user = this._.find(this.users, { id: userId });
    return this._.get(user, 'picture', '/img/user-default.png');
  }

  getGroupAdminName(userId) {
    const user = this._.find(this.users, { id: userId });
    return this._.get(user, 'name');
  }

  isGroupAdmin(group) {
    return this._.includes(group.leaders, this.Me.id);
  }

  setSorting(field) {
    if (this.sorting.field === field) {
      this.sorting.reverse = !this.sorting.reverse;
    } else {
      this.sorting.field = field;
      this.sorting.reverse = false;
    }
  }

  getSortingDirectionClass(field) {
    if (this.sorting.field === field) {
      return this.sorting.reverse
        ? 'fa fa-sort-down fa-fw'
        : 'fa fa-sort-up fa-fw';
    }
  }
}

GroupListComponent.$inject = [
  '_',
  '$state',
  '$uibModal',
  'Authorization',
  'Users',
  'Groups',
  'Me',
  'toastr',
  'gettextCatalog',
  '$ngRedux',
];

angular.module('cdApp.intranet').component('cdGroupListState', {
  templateUrl: '@/app/intranet/group-list/group-list.component.html',
  controller: GroupListComponent,
});
