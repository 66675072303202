import { Button, Space, Dropdown } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';

import gettextCatalog from '../../../../services/I18nService';
import { OrderType } from '../../../../shared/models/table';
import getTextCatalog from '../../../../services/I18nService';
import {
  CdAngleDown,
  CdEditIcon,
  EventIcons,
} from '../../../../shared/components/Icons';
import { ColumnType } from '../../../../shared/components/cd-table';
import { IntentionsTypes } from '../../types/intention.type';
import { EventsRotaTableValue } from '../rotas/EventsRotaTable';

import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';
import { AssignedIntentionsAtom } from '@/react/calendar/store/events/eventRotaDuties';
import NotificationService from '@/react/services/NotificationService';
import { IntentionPriorityTypes } from '@/react/intention/models/intention';

const RemovedContainer = styled.div`
  text-decoration: line-through;
`;

const IntentionsColumns = ({
  maxNumberOfPrimaryIntentions,
  setExtraData,
}: {
  maxNumberOfPrimaryIntentions?: number;

  setExtraData: (value) => void;
}) => {
  const setAssignedIntentions = useSetRecoilState(AssignedIntentionsAtom);
  const itemsPrimaryOption = {
    key: '1',
    label: gettextCatalog.getString('Make weitere intention'),
    icon: <CdEditIcon />,
  };
  const itemsSecondaryOption = {
    key: '2',
    label: (
      <CdTooltip
        title={
          maxNumberOfPrimaryIntentions === 0
            ? gettextCatalog.getString(
                'The event main category does not allow primary intentions.'
              )
            : undefined
        }
      >
        {gettextCatalog.getString('Make primary intention')}
      </CdTooltip>
    ),
    icon: <CdEditIcon />,
    disabled: maxNumberOfPrimaryIntentions === 0,
  };
  const itemsRemoveOption = {
    key: '3',
    label: gettextCatalog.getString('Remove'),
    icon: <EventIcons.RemoveIntention />,
  };
  const itemsAssignOption = {
    key: '4',
    label: gettextCatalog.getString('Assign'),
    icon: <EventIcons.AddIntentions />,
  };

  const handleRemoveIntention = (removedIntention) => {
    if (removedIntention.access.canUnAssign) {
      if (removedIntention.assignedBy === 'frontend') {
        setAssignedIntentions((prev) => {
          const newItems = prev.items.filter(
            (intention) => removedIntention.id !== intention.id
          );
          return { items: newItems, total: newItems.length };
        });
        setExtraData((prev) => [
          ...prev.filter((intention) => removedIntention.id !== intention.id),
        ]);
      } else {
        setAssignedIntentions((prev) => {
          const newItems = prev.items.map((intention) => {
            if (intention.id === removedIntention.id) {
              return {
                ...intention,
                isRemoved: true,
              };
            } else {
              return intention;
            }
          });
          return { items: newItems, total: newItems.length };
        });
        setExtraData((prev) =>
          prev.map((intention) => {
            if (intention.id === removedIntention.id) {
              return {
                ...intention,
                isRemoved: true,
              };
            } else {
              return intention;
            }
          })
        );
      }
    } else {
      NotificationService.notifyError(
        getTextCatalog.getString('You can not remove this intention.')
      );
    }
  };

  const handleMakePrimaryIntention = (editIntention) => {
    setAssignedIntentions((prev) => {
      const numberOfIntentionsWithPrimaryPriority = prev.items.filter(
        (intention) =>
          intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
          intention.priority === 'Erstintention'
      ).length;
      if (maxNumberOfPrimaryIntentions === 1) {
        const newItems = prev.items.map((intention) => {
          if (
            intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
            intention.priority === 'Erstintention'
          ) {
            return {
              ...intention,
              priority: IntentionPriorityTypes.WEITERLEITUNG,
            };
          } else if (intention.id === editIntention.id) {
            return {
              ...intention,
              priority: IntentionPriorityTypes.PERSOLVIERT,
            };
          } else {
            return intention;
          }
        });
        return { items: newItems, total: newItems.length };
      } else {
        if (
          numberOfIntentionsWithPrimaryPriority < maxNumberOfPrimaryIntentions
        ) {
          const newItems = prev.items.map((intention) => {
            if (intention.id === editIntention.id) {
              return {
                ...intention,
                priority: IntentionPriorityTypes.PERSOLVIERT,
              };
            } else {
              return intention;
            }
          });
          return { items: newItems, total: newItems.length };
        } else {
          NotificationService.notifyError(
            getTextCatalog.getString(
              'You cannot have more than {{maxNumberOfPrimaryIntentions}} primary intentions.',
              { maxNumberOfPrimaryIntentions }
            )
          );
          return prev;
        }
      }
    });
  };
  const handleMakeSecondaryIntention = (editIntention) => {
    setAssignedIntentions((prev) => {
      const numberOfIntentionsWithPrimaryPriority = prev.items.filter(
        (intention) =>
          intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
          intention.priority === 'Erstintention'
      ).length;
      if (
        maxNumberOfPrimaryIntentions > 0 &&
        numberOfIntentionsWithPrimaryPriority > 1
      ) {
        const newItems = prev.items.map((intention) => {
          if (intention.id === editIntention.id) {
            return {
              ...intention,
              priority: IntentionPriorityTypes.WEITERLEITUNG,
            };
          } else {
            return intention;
          }
        });
        return { items: newItems, total: newItems.length };
      } else {
        NotificationService.notifyError(
          getTextCatalog.getString(
            'You should have at least one primary intention.'
          )
        );
        return prev;
      }
    });
  };
  const handleReAssignIntention = (intention) => {
    setAssignedIntentions((prev) => {
      const newItems = prev.items.map((item) => {
        if (item.id === intention.id) {
          return {
            ...item,
            isRemoved: false,
          };
        } else {
          return item;
        }
      });
      return { items: newItems, total: newItems.length };
    });
    setExtraData((prev) =>
      prev.map((item) => {
        if (item.id === intention.id) {
          return {
            ...item,
            isRemoved: false,
          };
        } else {
          return item;
        }
      })
    );
  };
  const getAssignedIntentionColumns: ColumnType<
    EventsRotaTableValue['shiftsAndIntentions'][number]['intentions'][number]
  >[] = [
    {
      title: getTextCatalog.getString('Number'),
      dataIndex: 'reference',
      orderType: OrderType.NUMBER,
      key: 'refrence',
      render: (reference, record) =>
        record.isRemoved ? (
          <CdTooltip
            title={gettextCatalog.getString(
              'The intention will be removed after saving the event.'
            )}
          >
            <RemovedContainer>{reference} </RemovedContainer>
          </CdTooltip>
        ) : (
          <span>{reference}</span>
        ),
    },
    {
      title: getTextCatalog.getString('Intention'),
      dataIndex: 'text',
      orderType: OrderType.NUMBER,
      key: 'text',
      render: (text, record) =>
        record.isRemoved ? (
          <CdTooltip
            title={gettextCatalog.getString(
              'The intention will be removed after saving the event.'
            )}
          >
            <RemovedContainer>{text} </RemovedContainer>
          </CdTooltip>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: gettextCatalog.getString('Founder'),
      dataIndex: 'founder',
      key: 'founder',
      render: (founder, record) =>
        record.isRemoved ? (
          <CdTooltip
            title={gettextCatalog.getString(
              'The intention will be removed after saving the event.'
            )}
          >
            <RemovedContainer>{founder} </RemovedContainer>
          </CdTooltip>
        ) : (
          <span>{founder}</span>
        ),
    },
    {
      title: gettextCatalog.getString('Priority'),
      dataIndex: 'priority',
      key: 'priority',
      render: (priority, record) =>
        priority === IntentionPriorityTypes.PERSOLVIERT ||
        priority === 'Erstintention' ? (
          record.isRemoved ? (
            <CdTooltip
              title={gettextCatalog.getString(
                'The intention will be removed after saving the event.'
              )}
            >
              <RemovedContainer>Erstintention</RemovedContainer>
            </CdTooltip>
          ) : (
            'Erstintention'
          )
        ) : record.isRemoved ? (
          <CdTooltip
            title={gettextCatalog.getString(
              'The intention will be removed after saving the event.'
            )}
          >
            <RemovedContainer>Weitere</RemovedContainer>
          </CdTooltip>
        ) : (
          'Weitere'
        ),
    },
    {
      title: null,
      dataIndex: null,
      key: 'assign',
      fixed: 'right',
      width: 100,
      render: (intention) => {
        let items = [];
        if (
          intention.isRemoved &&
          (intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
            intention.priority === 'Erstintention')
        ) {
          items = [itemsPrimaryOption, itemsAssignOption];
        }
        if (
          intention.isRemoved &&
          (intention.priority === IntentionPriorityTypes.WEITERLEITUNG ||
            intention.priority === 'Weitere')
        ) {
          items = [itemsSecondaryOption, itemsAssignOption];
        }
        if (
          !intention.isRemoved &&
          (intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
            intention.priority === 'Erstintention')
        ) {
          items = [itemsPrimaryOption, itemsRemoveOption];
        }
        if (
          !intention.isRemoved &&
          (intention.priority === IntentionPriorityTypes.WEITERLEITUNG ||
            intention.priority === 'Weitere')
        ) {
          items = [itemsSecondaryOption, itemsRemoveOption];
        }
        return (
          <Space
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Dropdown
              menu={{
                items,
                onClick: ({ key }) => {
                  switch (key) {
                    case '1':
                      handleMakeSecondaryIntention(intention);
                      break;
                    case '2':
                      handleMakePrimaryIntention(intention);
                      break;
                    case '3':
                      handleRemoveIntention(intention);
                      break;
                    case '4':
                      handleReAssignIntention(intention);
                      break;
                    default:
                      break;
                  }
                },
              }}
            >
              <Button size="small">
                <Space>
                  {gettextCatalog.getString('Actions')}
                  <CdAngleDown />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const getUnAssignedIntentionColumns: ColumnType<IntentionsTypes>[] = [
    {
      title: getTextCatalog.getString('Number'),
      dataIndex: 'formattedReferenceNumber',
      orderType: OrderType.NUMBER,
      key: 'formattedReferenceNumber',
    },
    {
      title: getTextCatalog.getString('Intention'),
      dataIndex: 'intentionText',
      orderType: OrderType.NUMBER,
      key: 'intentionText',
    },
    {
      title: gettextCatalog.getString('Churches'),
      dataIndex: 'church',
      key: 'church',
    },
    {
      title: gettextCatalog.getString('Resource'),
      dataIndex: 'resource',
      key: 'resource',
    },
    {
      title: 'Wunschdatum',
      dataIndex: 'preferredDate',
      key: 'preferredDate',
    },
    {
      title: 'Terminwunsch',
      dataIndex: 'preferredNote',
      key: 'preferredNote',
    },
    {
      title: 'Stifter',
      dataIndex: 'founder',
      key: 'founder',
    },
    {
      title: null,
      dataIndex: null,
      key: 'assign',
      fixed: 'right',
      width: 100,
      render: (intention) => (
        <Space
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Button
            type="primary"
            size="small"
            disabled={intention?.assignedBy === 'frontend'}
            style={{ marginRight: 8 }}
            onClick={() => {
              setAssignedIntentions((prev) => {
                const numberOfIntentionsWithPrimaryPriority = prev.items.filter(
                  (intention) =>
                    intention.priority === IntentionPriorityTypes.PERSOLVIERT ||
                    intention.priority === 'Erstintention'
                ).length;
                if (
                  numberOfIntentionsWithPrimaryPriority <
                  maxNumberOfPrimaryIntentions
                ) {
                  const newItems = [
                    ...prev.items,
                    {
                      id: intention.id,
                      text: intention.intentionText,
                      reference: intention.formattedReferenceNumber,
                      intentionType: intention.fee.name,
                      founder: intention.founder,
                      priority: IntentionPriorityTypes.PERSOLVIERT,
                      access: { canUnassign: true },
                      assignedBy: 'frontend',
                    },
                  ];
                  return { items: newItems, total: newItems.length };
                } else {
                  const newItems = [
                    ...prev.items,
                    {
                      id: intention.id,
                      text: intention.intentionText,
                      reference: intention.formattedReferenceNumber,
                      intentionType: intention.fee.name,
                      founder: intention.founder,
                      priority: IntentionPriorityTypes.WEITERLEITUNG,
                      access: { canUnassign: true },
                      assignedBy: 'frontend',
                    },
                  ];
                  return { items: newItems, total: newItems.length };
                }
              });
              setExtraData((prev) => [
                ...prev,
                {
                  id: intention.id,
                  text: intention.intentionText,
                  reference: intention.formattedReferenceNumber,
                  intentionType: intention.fee.name,
                  founder: intention.founder,
                  priority: IntentionPriorityTypes.WEITERLEITUNG,
                  access: { canUnassign: true },
                  assignedBy: 'frontend',
                },
              ]);
            }}
          >
            <EventIcons.AddIntentions />
            {gettextCatalog.getString('Assign')}
          </Button>
        </Space>
      ),
    },
  ];

  return { getAssignedIntentionColumns, getUnAssignedIntentionColumns };
};

export default IntentionsColumns;
