import React from 'react';
import { Button, Dropdown, Space, FormInstance, MenuProps } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import {
  CdAngleDown,
  CdCopyIcon,
  CdDeleteIcon,
  CdNewTemplate,
  CdSave,
  CdSchedule,
  CdSendMessage,
  CdShare,
  CdTemplates,
  CdTestMessage,
} from '../../../shared/components/Icons';
import gettextCatalog from '../../../services/I18nService';

import { MessageType, PeopleMessage } from '@/react/people/types/message';
import { navigate } from '@/react/services/StateServiceFactory';
import { useTemplates } from '@/react/people/hooks/useTemplates';
import {
  useHandleMessageSave,
  validatePeopleMessageForm,
} from '@/react/people/hooks/useMailEditor';
import {
  CdConfirmModalProps,
  showConfirmModal,
} from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

interface NavigationButtonProps {
  message: PeopleMessage;
  handleCopy: () => void;
  handleDelete: () => Promise<void>;
  sendMessage: (message: PeopleMessage, when?: Date | moment.Moment) => void;
  sendTestMessage: (message: PeopleMessage) => void;
  saveMessage: (message: PeopleMessage) => Promise<PeopleMessage>;
  form: FormInstance;
  formValidationError: () => void;
  debouncedOnFormChange: _.DebouncedFunc<any>;
}
const NavigationButtons = ({
  message,
  handleCopy,
  handleDelete,
  sendMessage,
  sendTestMessage,
  saveMessage,
  form,
  formValidationError,
  debouncedOnFormChange,
}: NavigationButtonProps) => {
  const { openWarningBeforeChangingTemplate, openSaveNewTemplate } =
    useTemplates();
  const { handleSave } = useHandleMessageSave({
    saveMessage,
    form,
    debouncedOnFormChange,
  });
  const showShareNewsletterModal = (message: PeopleMessage) =>
    NiceModal.show('ShareNewsletterModal', { message });
  const showScheduleMailSendModal = (message) =>
    NiceModal.show('ScheduleMailSendModal', { sendMessage, message });

  const confirmDeleteModal: CdConfirmModalProps = {
    title: gettextCatalog.getString('Delete message'),
    message: (
      <>{gettextCatalog.getString('Do you want to delete the message')}</>
    ),
    icon: <CdDeleteIcon />,
    okButtonProps: { danger: true },
    okText: gettextCatalog.getString('Delete'),
    onOk: () =>
      handleDelete().then(() => navigate('app.private.people.messages.list')),
  };

  const confirmSendModal: (message: PeopleMessage) => CdConfirmModalProps = (
    message
  ) => ({
    title: gettextCatalog.getString('Send message'),
    message: gettextCatalog.getString('Do you want to send the message'),
    icon: <CdSendMessage />,
    okText: gettextCatalog.getString('Send'),
    onOk: () => sendMessage(message),
  });

  const isScheduled = message?.scheduled;
  const isSent = message?.sent;

  const moreMenuDropdown: MenuProps['items'] = [
    {
      key: '1',
      label: gettextCatalog.getString('Copy'),
      onClick: handleCopy,
      icon: <CdCopyIcon />,
    },
  ];
  if (!isSent) {
    moreMenuDropdown.push({
      key: '3',
      label: gettextCatalog.getString('Delete'),
      onClick: () => showConfirmModal(confirmDeleteModal),
      icon: <CdDeleteIcon />,
    });
  }
  if (
    message?.type === MessageType.ADVANCED ||
    message?.type === MessageType.SIMPLE
  ) {
    moreMenuDropdown.push({
      key: 'publicUrl',
      label: (isScheduled &&
        gettextCatalog.getString('Share your message')) || (
        <CdTooltip title="The message needs to be sent first">
          {gettextCatalog.getString('Share your message')}
        </CdTooltip>
      ),
      onClick: () => showShareNewsletterModal(message),
      icon: <CdShare />,
      disabled: !isScheduled,
    });
    if (message?.type === MessageType.ADVANCED) {
      if (!isScheduled) {
        moreMenuDropdown.push({
          key: '4',
          label: gettextCatalog.getString('Switch template'),
          onClick: openWarningBeforeChangingTemplate,
          icon: <CdTemplates />,
        });
      }
      moreMenuDropdown.push({
        key: '5',
        label: gettextCatalog.getString('Save as new template'),
        onClick: openSaveNewTemplate,
        icon: <CdNewTemplate />,
      });
    }
  }

  const sendMenuDropDown: MenuProps['items'] = [
    {
      key: '1',
      label: gettextCatalog.getString('Send me a test'),
      onClick: () =>
        validatePeopleMessageForm({
          validateFields: ['files', 'churches'],
          formValidationError,
          form,
        })
          .then(handleSave)
          .then(sendTestMessage),
      icon: <CdTestMessage />,
    },
    {
      key: '2',
      label: gettextCatalog.getString('Send later'),
      onClick: () =>
        validatePeopleMessageForm({ formValidationError, form }).then(() =>
          handleSave().then((message) => showScheduleMailSendModal(message))
        ),

      icon: <CdSchedule />,
    },
    {
      key: '3',
      label: gettextCatalog.getString('Send now'),
      icon: <CdSchedule />,
      onClick: () =>
        validatePeopleMessageForm({ formValidationError, form }).then(() =>
          handleSave().then((message) =>
            showConfirmModal(confirmSendModal(message))
          )
        ),
    },
  ].filter(Boolean);

  return (
    <Space
      align="end"
      style={{
        marginRight: '8px',
        minWidth: '300px',
        justifyContent: 'flex-end',
      }}
    >
      <Dropdown menu={{ items: moreMenuDropdown }}>
        <Button icon={<CdAngleDown />}>
          {gettextCatalog.getString('More')}
        </Button>
      </Dropdown>

      {!isSent && (
        <Button
          icon={<CdSave />}
          onClick={() =>
            validatePeopleMessageForm({
              validateFields: ['files'],
              formValidationError,
              form,
            }).then(() => handleSave())
          }
        >
          {gettextCatalog.getString('Save')}
        </Button>
      )}
      {isScheduled ? (
        <Button icon={isSent ? <CdSendMessage /> : <CdSchedule />} disabled>
          {isSent
            ? gettextCatalog.getString('Sent')
            : gettextCatalog.getString('Scheduled')}
        </Button>
      ) : (
        <CdTooltip
          title={
            !message?.id
              ? gettextCatalog.getString('Please save the message first.')
              : ''
          }
        >
          <div>
            <Dropdown
              menu={{ items: sendMenuDropDown }}
              disabled={!message?.id}
            >
              <Button
                type="primary"
                icon={<CdAngleDown />}
                disabled={!message?.id}
              >
                {gettextCatalog.getString('Send')}
              </Button>
            </Dropdown>
          </div>
        </CdTooltip>
      )}
    </Space>
  );
};

export default NavigationButtons;
