import React from 'react';
import { Spin } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { useAddUserToRota } from '../../hooks/use-add-user-to rota';

import 'react-image-crop/dist/ReactCrop.css';
import { AddUserToRotaForm } from './AddUserToRotaForm';

import getTextCatalog from '@/react/services/I18nService';
import CdModal from '@/react/shared/components/cd-modal/CdModal';
import { DateInputValue } from '@/react/calendar/event-details/components/date-input/DateInput';

export interface RotaDetails {
  calendarId: number;
  taskId: number;
  date: Pick<DateInputValue, 'startDate' | 'endDate' | 'rrule' | 'allDay'>;
  // Shift info
  note: string;
  users: { id: number; canUnassign: boolean }[];
  // Rota info
  rotaDutyName: string;
  required: number;
}

type AddUserToRotaModalProps = {
  rotaDetails: RotaDetails;
  canEditUsers: boolean;
  initialRrule: string;
  disableOkButton?: boolean;
  defaultParishes?: number[];
};

export const openAddUserToRotaModal = (props: AddUserToRotaModalProps) =>
  NiceModal.show('AddUserToRotaModal', {
    rotaDetails: props.rotaDetails,
    canEditUsers: props.canEditUsers,
    initialRrule: props.initialRrule,
    disableOkButton: props.disableOkButton,
    defaultParishes: props.defaultParishes,
  });

export const AddUserToRotaModal = NiceModal.create(
  ({
    rotaDetails,
    canEditUsers,
    initialRrule,
    disableOkButton,
    defaultParishes,
  }: AddUserToRotaModalProps) => {
    const {
      modal,
      form,
      userAssignedList,
      userAssignedIds,
      busyUsers,
      loading,
      handleChange,
      handleRemoveAssignedUser,
      handleOnOk,
      keywordToSearchUsersByName,
      setKeywordToSearchusersByName,
      selectorOptionsUsers,
      filterParishIds,
      setFilterParishIds,
      startDate,
      token,
      isMultiParish,
      allDay,
    } = useAddUserToRota({ rotaDetails, initialRrule, defaultParishes });
    return (
      <CdModal
        title={getTextCatalog.getString('Assign user')}
        okText={getTextCatalog.getString('Add')}
        onOk={handleOnOk}
        modal={modal}
        disabledOkButton={disableOkButton}
      >
        <Spin spinning={loading}>
          <AddUserToRotaForm
            form={form}
            startDate={startDate}
            allDay={allDay}
            rotaDutyName={rotaDetails.rotaDutyName}
            note={rotaDetails.note}
            canEditUsers={canEditUsers}
            isMultiParish={isMultiParish}
            filterParishIds={filterParishIds}
            setFilterParishIds={setFilterParishIds}
            handleChange={handleChange}
            selectorOptionsUsers={selectorOptionsUsers}
            required={rotaDetails.required}
            userAssignedIds={userAssignedIds}
            keywordToSearchUsersByName={keywordToSearchUsersByName}
            setKeywordToSearchusersByName={setKeywordToSearchusersByName}
            userAssignedList={userAssignedList}
            busyUsers={busyUsers}
            handleRemoveAssignedUser={handleRemoveAssignedUser}
            token={token}
            eventTitle={null}
          />
        </Spin>
      </CdModal>
    );
  }
);
