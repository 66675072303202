import { FormInstance } from 'antd/es/form/Form';
import { debounce, isEmpty, uniq } from 'lodash';
import { NamePath } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  MessageType,
  PeopleMessage,
  PeopleMessageRecipient,
} from '../types/message';
import { Segment } from '../types/segment.type';

import { Churches } from '@/react/user/store/user-session';
import NotificationService from '@/react/services/NotificationService';

interface useMailEditorProps {
  type: MessageType;
  form: FormInstance;
  message: PeopleMessage;
  saveMessage: (message: PeopleMessage) => void;
  to: PeopleMessageRecipient[];
  contactIds?: number[];
}

export const validatePeopleMessageForm = ({
  validateFields,
  form,
  formValidationError,
}: {
  validateFields?: NamePath[];
  form: FormInstance;
  formValidationError: () => void;
}) =>
  form.validateFields(validateFields).catch((err) => {
    if (!isEmpty(err.errorFields)) {
      err.errorFields?.forEach((field) => {
        field.errors?.forEach((error) => {
          NotificationService.notifyError(error);
        });
      });
      formValidationError();
    }
    throw err;
  });

export default function useMailEditor({
  type,
  form,
  message,
  saveMessage,
  to = [],
  contactIds = [],
}: useMailEditorProps) {
  const churches = useRecoilValue(Churches);
  const showChurchSelector = window?.cdApp?.showChurchSelector;
  const onFormChange = useCallback(() => {
    const payload = form.getFieldsValue(true);
    const selectedChurches = showChurchSelector
      ? payload.churches
      : churches.map(({ id }) => id);
    payload.churches = selectedChurches;
    validatePeopleMessageForm({
      validateFields: [
        'files',
        ['content', 'blogTitle'],
        ['content', 'eventsTitle'],
        ['content', 'contributionsTitle'],
        ['content', 'formsTitle'],
      ],
      form,
      formValidationError: () => null,
    }).then(() => saveMessage(payload));
  }, [saveMessage, form, showChurchSelector, churches]);
  const debouncedOnFormChange = useMemo(
    () => debounce(onFormChange, 3000),
    [onFormChange]
  );

  useEffect(() => {
    form.setFieldsValue({ picture: message.picture });
  }, [message.picture, form]);

  useEffect(() => {
    form.setFieldsValue({ rendered: message?.rendered });
  }, [message.rendered, form]);

  useEffect(() => {
    form.setFieldsValue({ events: message?.events });
  }, [message.events, form]);

  useEffect(() => {
    form.setFieldsValue({ news: message?.news });
  }, [message.news, form]);

  useEffect(() => {
    form.setFieldsValue({ contributions: message?.contributions });
  }, [message.contributions, form]);

  useEffect(() => {
    form.setFieldsValue({ forms: message?.forms });
  }, [message.forms, form]);

  useEffect(() => {
    form.setFieldsValue({
      ...message,
      to: uniq(
        recipientsToFormStructure(message?.to)
          .concat(recipientsToFormStructure(to))
          .concat(contactIds.map((c) => `person-${c}`))
      ),
      churches: message?.churches?.map(({ id }) => id),
      files:
        (type === MessageType.BASIC &&
          message?.files.map((file) => ({
            ...file,
            key: file.id,
            uid: file.id,
            name: file.title,
            status: 'done',
            url: file.url,
          }))) ||
        undefined,
    });
    // Only run on initialization. Monitor message.id to run this again when opening up another message.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.id]);

  return { debouncedOnFormChange };
}

export const useHandleMessageSave = ({
  saveMessage,
  form,
  debouncedOnFormChange,
}: {
  saveMessage: (payload: PeopleMessage) => Promise<PeopleMessage>;
  form: FormInstance;
  debouncedOnFormChange: _.DebouncedFunc<any>;
}) => {
  const churches = useRecoilValue(Churches);
  const showChurchSelector = window?.cdApp?.showChurchSelector;

  const handleSave = useCallback(() => {
    debouncedOnFormChange.cancel();
    const payload = form.getFieldsValue(true);
    const selectedChurches = showChurchSelector
      ? payload.churches
      : churches.map(({ id }) => id);
    payload.churches = selectedChurches;

    return saveMessage(payload);
  }, [debouncedOnFormChange, form, showChurchSelector, churches, saveMessage]);

  return { handleSave };
};

const recipientsToFormStructure = (recipients) =>
  recipients?.map(
    (target) =>
      ((target as Segment).setup
        ? `segment-${target.id}`
        : `person-${target.id}`) || []
  );
