import { isEmpty, pick } from 'lodash';

import { EventHistory } from '../types/revisions.type';
import gettextCatalog from '../../../services/I18nService';

import { mainApi } from '@/react/api';
import { DetailedEvent, EventType } from '@/react/calendar/models/calendar';
import { handleError } from '@/react/services/ErrorHandlingService';
import { getString } from '@/react/services/I18nService';
import NotificationService from '@/react/services/NotificationService';

export const getCalendar = async (
  calendarId: number,
  clientVersion = 3,
  eventType?: EventType
) => {
  let response;
  if (eventType === EventType.External) {
    response = await mainApi.get<DetailedEvent>(
      `/calendar/external-event/${calendarId}`
    );
  } else {
    response = await mainApi.get<DetailedEvent>(
      `/calendar/${calendarId}?clientVersion=${clientVersion}`
    );
  }
  if (!response.ok) {
    if (response.status === 404) {
      NotificationService.notifyError(
        gettextCatalog.getString('This event no longer exists')
      );
    } else {
      handleError(response);
      return null;
    }
  }
  return response.data;
};
export const eventInfoForSpecialRevision = async ({
  revisionId,
  eventId,
}: {
  revisionId: number;
  eventId: number;
}) => {
  const response = await mainApi.get<EventHistory>(
    `/revisions/event/${eventId}/view/${revisionId}?clientVersion=3`
  );
  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};

export const getEventCopy = async (calendarId: number) => {
  const response = await mainApi.get<DetailedEvent>(
    `/calendar/${calendarId}?clientVersion=3`
  );
  if (!response.ok) {
    handleError(response);
    return null;
  }
  const fieldsAccess = response.data.fields;
  /*
  # What should not be copied (https://app.shortcut.com/churchdesk/story/21066/event-planning-copy-event):
  - repeat rule
  - facebook
  - sensitive information
  - comments
  - attendance
  - version history
  - posters
  - invites
  - assigned rota users
  - intentions
  */
  const sogndk = pick(response.data.sogndk, [
    'categoryId',
    'churchId',
    'export',
    'pastorId',
    'sognId',
  ]);

  const copiedEvent = pick(response.data, [
    'title',
    'startDate',
    'type',
    'endDate',
    'allDay',
    'hideEndTime',
    'resources',
    'preparationStartDate',
    'cleanupEndDate',
    'churches',
    'users',
    'mainCategory',
    'visibility',
    'locationName',
    'locationObj',
    'taxonomies',
    'internalNote',
    // Attached files
    'contributor',
    'imageId',
    'summary',
    'description',
    'groups',
    'showInSlideshow',
    'price',
    'rotas',
    'files',
    // added form?
  ]) as DetailedEvent;
  copiedEvent.sogndk = sogndk;
  for (const fieldInCopiedEvent in copiedEvent) {
    if (
      !fieldsAccess[fieldInCopiedEvent]?.canEdit &&
      !['type', 'visibility'].includes(fieldInCopiedEvent)
    ) {
      copiedEvent[fieldInCopiedEvent] = undefined;
    }
  }
  copiedEvent.fields = fieldsAccess;
  copiedEvent.title = getString('{{eventTitle}} (copy)', {
    eventTitle: copiedEvent.title,
  });
  copiedEvent.isCopiedEvent = true;

  // Translate visibility to internal-group or internal-all
  // @ts-ignore: internal-group and internal-all is allowed in the form state
  copiedEvent.visibility =
    copiedEvent.visibility === 'internal'
      ? isEmpty(copiedEvent?.groups)
        ? 'internal-all'
        : 'internal-group'
      : copiedEvent.visibility;

  // MH Aug 13, 2024 it was decided with BC and KS that the visibility should be copied as is and not downgraded.
  // Check if the copied visibility is allowed. If not, set it to the first allowed value.
  /* if (
    !fieldsAccess.visibility.allowedValues.includes(copiedEvent.visibility) &&
    fieldsAccess.visibility.allowedValues.length > 0
  ) {
    // If internal-all is available select only if groups are empty.
    if (
      fieldsAccess.visibility.allowedValues.includes('internal-all') &&
      isEmpty(copiedEvent?.groups)
    ) {
      // @ts-ignore: internal-group and internal-all is allowed in the form state
      copiedEvent.visibility = 'internal-all';
    } else if (
      fieldsAccess.visibility.allowedValues.includes('internal-group') &&
      copiedEvent?.groups.length > 0
    ) {
      // @ts-ignore: internal-group and internal-all is allowed in the form state
      copiedEvent.visibility = 'internal-group';
    } else {
      copiedEvent.visibility = fieldsAccess.visibility
        .allowedValues[0] as Visibility;
    }
  } else if (!fieldsAccess.visibility.allowedValues.length) {
    // @ts-ignore: internal-group and internal-all is allowed in the form state
    copiedEvent.visibility = 'internal-group';
  }*/

  if (copiedEvent.type === EventType.Event && copiedEvent.imageId) {
    copiedEvent.cropKey = `${response.data.id}`;
  }
  if (copiedEvent?.rotas?.length > 0) {
    copiedEvent.shiftsAndIntentions = [
      {
        calendarId: copiedEvent.id,
        intentions: [],
        shifts: copiedEvent.rotas.map((rota) => ({
          taskId: rota.taskId,
          access: { canAssignMySelf: true, canEdit: true },
        })),
      },
    ];
  }

  return copiedEvent;
};
