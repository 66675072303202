'use strict';
import { compact } from 'lodash';

import cdApp from '../../../react/config';

class OtherSettingsComponent {
  constructor(
    _,
    moment,
    $http,
    $uibModal,
    gettextCatalog,
    toastr,
    cdApp,
    PeoplePreferences,
    Church,
    facebookService,
    appUtils,
    Organizations
  ) {
    'ngInject';

    this._ = _;
    this.moment = moment;
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.cdApp = cdApp;
    this.PeoplePreferences = PeoplePreferences;
    this.Church = Church;
    this.facebookService = facebookService;
    this.appUtils = appUtils;
    this.Organizations = Organizations;
  }

  $onInit() {
    this.shouldShowHelpLink =
      this._.get(this.cdApp, 'organization.countryIso2') === 'gb';
    (this.isChurchDeskEmployee = this.cdApp.me?.isChurchDeskEmployee),
      (this.fields = [
        'enableImageCredits',
        'requireImageCredits',
        'privacyPolicy',
        'siteDomain',
        'taxPercentage',
        'expirationEventInternalNote',
        'expirationEventSensitiveNote',
        'gebCustomerId',
        'gettextCatalog',
        'domain',
      ]);

    this.getOrganizationSettings();

    // Get all organizations' domains
    this.getOrganizationSites();
    this.getPartnerCategories();

    const release = _.get(cdApp, 'release') || 'no-info';
    if (release.length > 30) {
      // Cloudflare deployment
      this.version = `CF-${release.slice(0, 7)}`;
    } else {
      this.version = release;
    }
    this.deleteEventDataIntervals = [
      {
        label: this.gettextCatalog.getString("Don't delete automatically"),
        value: 'do_not_delete_automatically',
      },
      { label: this.gettextCatalog.getString('30 days'), value: 30 },
      { label: this.gettextCatalog.getString('60 days'), value: 60 },
      { label: this.gettextCatalog.getString('90 days'), value: 90 },
      { label: this.gettextCatalog.getString('365 days'), value: 365 },
    ];
  }

  getOrganizationSettings() {
    const { _, toastr, Church, appUtils } = this;

    this.isLoading = true;
    Church.get()
      .$promise.then((settings) => {
        this.canEditFields = _.mapValues(settings.fields, (field) =>
          _.get(field, 'canEdit', false)
        );
        this.canEditAnyField = _.some(this.canEditFields);

        this.settings = _(settings)
          .pick(this.fields)
          .pickBy((value, field) => {
            const canEditField = _.get(this.canEditFields, field) === true;
            return canEditField;
          })
          .value();
        this.settings.expirationEventInternalNote = this.settings
          .expirationEventInternalNote
          ? this.settings.expirationEventInternalNote
          : 'do_not_delete_automatically';
        this.settings.expirationEventSensitiveNote = this.settings
          .expirationEventSensitiveNote
          ? this.settings.expirationEventSensitiveNote
          : 'do_not_delete_automatically';
        this.settings.publishToMonoBlog = settings?.publishToMonoBlog;
        this.settings.monoBlogsMigrationComplete =
          settings?.monoBlogsMigrationComplete;
        this.facebookAccount = settings.facebook;
        this.generalChurchDeskLandingPage = `${cdApp.references.landingPage}/${
          cdApp.organization.locale.language || 'en'
        }/${cdApp.organization.id}`;
        this.settings.organizationName = settings.name;
        this.settings.organizationPartnerMembershipId =
          settings.organizationPartnerMembershipId;
        this.copyUrlToClipboard = function () {
          const url = document.querySelector(
            '#generalChurchDeskLandingPageUrl'
          );
          url.select();

          try {
            const successful = document.execCommand('copy');
            if (successful) {
              toastr.info(
                this.gettextCatalog.getString('Link copied to clipboard.')
              );
            } else {
              unsuccessful();
            }
          } catch (err) {
            unsuccessful();
          }

          function unsuccessful() {
            toastr.error(
              this.gettextCatalog.getString(
                "Couldn't copy link. Try right clicking on the link to copy."
              )
            );
          }
        };
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  saveSettings() {
    const { _, cdApp, toastr, gettextCatalog, PeoplePreferences } = this;

    let settings = _.clone(this.settings);
    if (_.isEmpty(settings.siteDomain)) {
      settings = _.omit(settings, 'siteDomain');
    }
    new PeoplePreferences(settings).$save(() => {
      // Update the local organization's settings
      const currentSettings = _.get(cdApp, 'organization.settings');
      _.assign(currentSettings, settings);
      toastr.success(gettextCatalog.getString('Your changes have been saved.'));
    });
  }

  /**
   * Should show the expiry date of the Facebook token only if it's closer than 1 year.
   * If it's far in the future, then there's no need to show it.
   */
  shouldShowExpiryDate() {
    const { moment } = this;
    if (!this.facebookAccount) return false;

    return moment(this.facebookAccount.expiresAt).diff(moment(), 'years') < 1;
  }

  authenticateFacebook() {
    this.isLoadingSDK = true;
    this.facebookService
      .authenticate()
      .then(() => {
        this.getOrganizationSettings();
      })
      .finally(() => {
        this.isLoadingSDK = false;
      });
  }

  getFacebookPageUrl() {
    return `<a href="${this.facebookAccount.page.link}" target="_blank" rel="noopener noreferrer">${this.facebookAccount.page.name}</a>`;
  }

  disconnectFromFacebook() {
    const { cdApp, $http, $uibModal, appUtils, toastr, gettextCatalog } = this;

    $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => gettextCatalog.getString('Disconnect from Facebook'),
          body: () =>
            gettextCatalog.getString(
              "Are you sure you want to disconnect your Facebook account? You won't be able to share events to your Facebook page anymore."
            ),

          options: {
            confirmButtonText: gettextCatalog.getString('Disconnect'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.isLoading = true;
        $http
          .post(
            `${cdApp.config.api.main}/church/settings?organizationId=${this.cdApp.organization.id}`,
            { facebook: null }
          )
          .then(
            () => {
              toastr.success(
                gettextCatalog.getString(
                  "You're now disconnected from Facebook."
                )
              );

              this.isLoading = false;
              this.facebookAccount = null;
            },
            (error) => {
              toastr.error(appUtils.getErrorMessage(error));
            }
          );
      });
  }

  getOrganizationSites() {
    const { cdApp, toastr, appUtils, $http } = this;
    $http
      .get(
        `${cdApp.config.api.main}/sites?organizationId=${this.cdApp.organization.id}`
      )
      .then((sites) => {
        this.organizationPrimaryDomains = compact(
          (sites?.data?.sites || []).map((site) => site.primaryDomain)
        );
        this.organizationPrimaryDomainNoMatch =
          !this.organizationPrimaryDomains.includes(this.domain);
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      });
  }

  getPartnerCategories() {
    const { cdApp, toastr, appUtils, $http } = this;
    $http
      .get(
        `${cdApp.config.api.partners}/partners/categories?organizationId=${this.cdApp.organization.id}`
      )
      .then((categories) => {
        this.partnerCategories = categories.data;
      })
      .catch((error) => {
        toastr.error(appUtils.getErrorMessage(error));
      });
  }

  isVisibleByLanguage(language) {
    return this.cdApp.organization.locale.language === language;
  }

  getGepLink() {
    const { gettextCatalog } = this;
    return `<a href="https://gemeindebrief-magazin.evangelisch.de/dazubuchen" target="_blank">${gettextCatalog.getString(
      'sign up here'
    )}</a>`;
  }
  getPartnerGuidLink() {
    const { gettextCatalog } = this;
    return `<a href="https://docs.churchdesk.com/partnership/" target="_blank">${gettextCatalog.getString(
      'here'
    )}</a>`;
  }
}
OtherSettingsComponent.$inject = [
  '_',
  'moment',
  '$http',
  '$uibModal',
  'gettextCatalog',
  'toastr',
  'cdApp',
  'PeoplePreferences',
  'Church',
  'facebookService',
  'appUtils',
  'Organizations',
];

angular.module('cdApp.settings').component('cdOtherSettingsState', {
  templateUrl: '@/app/settings/other-settings/other-settings.component.html',
  controller: OtherSettingsComponent,
});
