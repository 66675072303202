import { Card, Form, Col, Row, Input, Layout, Spin } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';

import { Statistics } from '../statistics/Statistics';
import { useMailEditor, useMessage } from '../../hooks';
import MessageFormDetails from '../shared/containers/MessageDetails';

import { MobilePreview } from './MobilePreview';
import { UnlayerEditor } from './UnlayerEditor';
import NavigationButtons from './NavigationButtons';

import gettextCatalog from '@/react/services/I18nService';
import {
  CdTabPaneTab,
  CdTabs,
} from '@/react/shared/components/tab-container/TabContainer';
import {
  MessageStatisticsFilters,
  MessageType,
} from '@/react/people/types/message';
import { CdDesktop, CdMobile } from '@/react/shared/components/Icons';
import { CdCard } from '@/react/shared/components/CdCard';
import { SaveNewTemplateModal } from '@/react/people/message-editor/advanced-editor/SaveNewTemplateModal';
import { IframeMailViewer } from '@/react/people/message-editor/shared/containers/IframeMailViewer';
import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';

const AdvancedMessageEditor = ({
  messageId,
  to,
  type,
  statisticsFilter,
}: {
  messageId?: number;
  to: any[];
  type: MessageType;
  statisticsFilter: MessageStatisticsFilters;
}) => {
  const [current, setCurrent] = useState('design');
  const [form] = Form.useForm();
  const {
    message,
    saveMessage,
    deleteMessage,
    cloneMessage,
    sendMessage,
    sendTestMessage,
    attachFile,
    refetchMessage,
  } = useMessage(messageId, type);
  const { debouncedOnFormChange } = useMailEditor({
    type: type,
    form,
    message,
    saveMessage,
    to,
  });

  const isScheduled = message?.scheduled;
  const isSent = message?.sent;
  let tabNumber = 0;
  useEffect(() => {
    if (isScheduled) setCurrent('statistics');
  }, [isScheduled]);

  const onChange = (current) => {
    setCurrent(current);
  };

  if (!message) {
    return (
      <CdNotFound
        title={gettextCatalog.getString('Not found')}
        subTitle={gettextCatalog.getString('The message could not be found.')}
      />
    );
  }

  const themeItems = [
    {
      key: '1',
      label: (
        <>
          <CdDesktop />
          {gettextCatalog.getString('Desktop')}
        </>
      ),
      children: (
        <div style={{ flexGrow: 1 }}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <IframeMailViewer
                minHeight="748px"
                value={getFieldValue('rendered')}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <>
          <CdMobile />
          {gettextCatalog.getString('Mobile')}
        </>
      ),
      children: (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <MobilePreview value={getFieldValue('rendered')} />
          )}
        </Form.Item>
      ),
    },
  ];

  const designTab = {
    key: 'design',
    label: (
      <CdTabPaneTab
        title={gettextCatalog.getString('Design')}
        subTitle={gettextCatalog.getString(`Create any design you'd like`)}
        tabNumber={!isSent ? ++tabNumber : tabNumber}
      />
    ),
    children: (
      <CdCard $fill>
        <Form.Item hidden={true} name="rendered">
          <Input />
        </Form.Item>
        <Form.Item name="content" noStyle>
          <UnlayerEditor
            form={form}
            attachFile={attachFile}
            displayMode="email"
          />
        </Form.Item>
      </CdCard>
    ),
  };

  const tabItems = [
    {
      key: 'toAndFrom',
      label: (
        <CdTabPaneTab
          title={gettextCatalog.getString('To and from')}
          subTitle={gettextCatalog.getString('Message details')}
          tabNumber={++tabNumber}
        />
      ),
      children: (
        <CdCard $fill style={{ padding: '24px' }}>
          <Row justify="center">
            <Col style={{ maxWidth: '800px' }} flex="1 1 auto">
              <MessageFormDetails isSent={!!isSent} />
            </Col>
          </Row>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <CdTabs
                defaultActiveKey="1"
                type="card"
                size="large"
                centered
                style={{ overflow: 'auto', background: 'white' }}
                items={themeItems}
              />
            </Col>
          </Row>
        </CdCard>
      ),
    },
  ];

  const statisticTab = {
    key: 'statistics',
    label: (
      <CdTabPaneTab
        title={gettextCatalog.getString('Statistics')}
        subTitle={gettextCatalog.getString(
          'Analyze the performance of your message'
        )}
        tabNumber={isScheduled ? ++tabNumber : tabNumber}
      />
    ),
    children: (
      <Card style={{ width: '100%' }}>
        <Suspense
          fallback={
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          }
        >
          <Statistics
            message={message}
            refetchMessage={refetchMessage}
            defaultFilter={statisticsFilter}
          />
        </Suspense>
      </Card>
    ),
  };

  if (!isSent) tabItems.splice(0, 0, designTab);
  if (isScheduled) tabItems.push(statisticTab);

  return (
    <Layout.Content className="app-main" style={{ padding: 0 }}>
      <SaveNewTemplateModal id="SaveNewTemplate" />
      <Form
        form={form}
        style={{ height: '100%' }}
        labelCol={{ span: 2 }}
        onValuesChange={debouncedOnFormChange}
      >
        <CdTabs
          activeKey={current}
          onChange={onChange}
          $fluidHeight
          tabBarStyle={{ marginBottom: 0 }}
          tabBarExtraContent={{
            right: (
              <NavigationButtons
                message={message}
                handleCopy={cloneMessage}
                handleDelete={deleteMessage}
                sendMessage={sendMessage}
                sendTestMessage={sendTestMessage}
                form={form}
                saveMessage={saveMessage}
                formValidationError={() => setCurrent('toAndFrom')}
                debouncedOnFormChange={debouncedOnFormChange}
              />
            ),
          }}
          $noInnerPadding
          items={tabItems}
        />
      </Form>
    </Layout.Content>
  );
};

export default AdvancedMessageEditor;
